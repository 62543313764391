import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Separator from "./separator";
import styles from "./faq-block.module.scss";

const FaqBlock = props => {
  const { questions } = props;
  return (
    <section className={styles.questions}>
      {questions.map((question, index) => (
        <React.Fragment key={question.question}>
          {index !== 0 && <Separator isColor />}
          <h4 className={styles.question}>{question.question}</h4>
          <div className={styles.answer}>
            <ReactMarkdown source={question.answer} />
          </div>
        </React.Fragment>
      ))}
    </section>
  );
};

FaqBlock.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default FaqBlock;
