import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import FaqBlock from "../components/faq-block";

const Faq = ({ data }) => {
  const { description, banner, questions } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Seo
        title={banner.title}
        description={description}
        imagePath={banner.bannerImage.publicURL}
      />
      <Hero
        fluid={banner.bannerImage.childImageSharp.fluid}
        title={banner.title}
      />
      <FaqBlock questions={questions} />
    </Layout>
  );
};

Faq.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default Faq;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "faq" } }) {
      frontmatter {
        description
        banner {
          title
          bannerImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blurb
        }
        questions {
          question
          answer
        }
        ctaBlock {
          button {
            name
            link
          }
        }
      }
    }
  }
`;
